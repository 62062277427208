var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.initOk)?_c('div',{staticClass:"container"},[_c('div',{staticStyle:{"padding-bottom":"70px"}},[_c('van-divider',{style:({
        borderColor: '#ee0a24',
        padding: '0 100px',
      })},[_vm._v(" 学杂费清单 ")]),_c('div',{staticClass:"box"},[_vm._l((_vm.fee.billMap.bills),function(item,index){return _c('div',{key:index,staticClass:"box-line"},[_c('span',[_vm._v(_vm._s(item.name))]),_c('span',[_vm._v("￥"+_vm._s(item.amount))])])}),_c('div',{staticClass:"box-line"},[_c('span',[_vm._v("绿色通道暂缓")]),_c('span',[_vm._v("-￥"+_vm._s(_vm.fee.defer))])]),_c('div',{staticClass:"box-line"},[_c('span',[_vm._v("合计")]),_c('span',{staticClass:"red"},[_vm._v("￥"+_vm._s(_vm.fee.amount - _vm.fee.defer))])])],2),_c('van-divider',{style:({
        borderColor: '#ee0a24',
        padding: '0 100px',
      })},[_vm._v(" 待缴清单 ")]),_c('div',{staticClass:"box"},[_vm._l((_vm.fee.billMap.unpaids),function(item,index){return _c('div',{key:index,staticClass:"box-line"},[_c('span',[_vm._v(_vm._s(item.name))]),_c('span',[_vm._v("￥"+_vm._s(item.amount))])])}),_c('div',{staticClass:"box-line"},[_c('span',[_vm._v("绿色通道暂缓")]),_c('span',[_vm._v("-￥"+_vm._s(_vm.fee.defer))])]),_c('div',{staticClass:"box-line"},[_c('span',[_vm._v("合计")]),_c('span',{staticClass:"red"},[_vm._v("￥"+_vm._s(_vm.fee.unpaid))])])],2),_c('van-divider',{style:({
        borderColor: '#ee0a24',
        padding: '0 100px',
      })},[_vm._v(" 已缴清单 ")]),_c('div',{staticClass:"box"},[_vm._l((_vm.fee.billMap.completeds),function(item,index){return _c('div',{key:index,staticClass:"box-line"},[_c('span',[_vm._v(_vm._s(item.name))]),_c('span',[_vm._v("￥"+_vm._s(item.amount))])])}),_c('div',{staticClass:"box-line"},[_c('span',[_vm._v("绿色通道暂缓")]),_c('span',[_vm._v("-￥"+_vm._s(_vm.fee.defer))])]),_c('div',{staticClass:"box-line"},[_c('span',[_vm._v("合计")]),_c('span',{staticClass:"red"},[_vm._v("￥"+_vm._s(_vm.fee.completed))])])],2)],1),_c('br'),_c('br'),_c('br'),_c('br'),_c('br'),_c('br'),(_vm.fee.unpaid > 0)?_c('van-submit-bar',{attrs:{"price":_vm.fee.unpaid * 100,"button-text":"马上缴费","tip-icon":"info-o"},on:{"submit":function($event){return _vm.navTo('/checkin/pay')}},scopedSlots:_vm._u([{key:"tip",fn:function(){return [_vm._v(" 友情提示：以下学生需在"),_c('b',{on:{"click":function($event){return _vm.navTo('/checkin/greenFee')}}},[_vm._v("绿色缴费通道")]),_vm._v("办理手续："),_c('br'),_vm._v(" 1. 办理了生源地助学贷款的学生。"),_c('br'),_vm._v(" 2. 需要缓交学费的学生。 ")]},proxy:true}],null,false,1360943935)}):_vm._e()],1):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }