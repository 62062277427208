<template>
  <div class="container" v-if="initOk">
    <div style="padding-bottom: 70px">
      <van-divider
        :style="{
          borderColor: '#ee0a24',
          padding: '0 100px',
        }"
      >
        学杂费清单
      </van-divider>
      <div class="box">
        <div
          class="box-line"
          v-for="(item, index) in fee.billMap.bills"
          :key="index"
        >
          <span>{{ item.name }}</span>
          <span>￥{{ item.amount }}</span>
        </div>
        <div class="box-line">
          <span>绿色通道暂缓</span>
          <span>-￥{{ fee.defer }}</span>
        </div>
        <div class="box-line">
          <span>合计</span>
          <span class="red">￥{{ fee.amount - fee.defer }}</span>
        </div>
      </div>
      <van-divider
        :style="{
          borderColor: '#ee0a24',
          padding: '0 100px',
        }"
      >
        待缴清单
      </van-divider>
      <div class="box">
        <div
          class="box-line"
          v-for="(item, index) in fee.billMap.unpaids"
          :key="index"
        >
          <span>{{ item.name }}</span>
          <span>￥{{ item.amount }}</span>
        </div>
        <div class="box-line">
          <span>绿色通道暂缓</span>
          <span>-￥{{ fee.defer }}</span>
        </div>
        <div class="box-line">
          <span>合计</span>
          <span class="red">￥{{ fee.unpaid }}</span>
        </div>
      </div>
      <van-divider
        :style="{
          borderColor: '#ee0a24',
          padding: '0 100px',
        }"
      >
        已缴清单
      </van-divider>
      <div class="box">
        <div
          class="box-line"
          v-for="(item, index) in fee.billMap.completeds"
          :key="index"
        >
          <span>{{ item.name }}</span>
          <span>￥{{ item.amount }}</span>
        </div>
        <div class="box-line">
          <span>绿色通道暂缓</span>
          <span>-￥{{ fee.defer }}</span>
        </div>
        <div class="box-line">
          <span>合计</span>
          <span class="red">￥{{ fee.completed }}</span>
        </div>
      </div>
    </div>
    <br />
    <br />
    <br />
    <br />
    <br />
    <br />
    <van-submit-bar
      v-if="fee.unpaid > 0"
      :price="fee.unpaid * 100"
      button-text="马上缴费"
      tip-icon="info-o"
      @submit="navTo('/checkin/pay')"
    >
      <template #tip>
        友情提示：以下学生需在<b @click="navTo('/checkin/greenFee')">绿色缴费通道</b>办理手续：<br />
        1. 办理了生源地助学贷款的学生。<br />
        2. 需要缓交学费的学生。
      </template>
    </van-submit-bar>
  </div>
</template>
<script>
import { Toast } from "vant";
import * as api from "@/apis/api";
export default {
  data() {
    return {
      initOk: false,
      fee: {
        amount: 0,
        completed: 0,
        defer: 0,
        unpaid: 0,
        billMap: {
          bills: [],
          completeds: [],
          unpaids: [],
        },
      },
    };
  },
  created() {
    this.findFee();
  },
  methods: {
    navTo(url) {
      this.$router.push(url);
    },
    findFee() {
      Toast.loading({
        message: "加载中...",
        forbidClick: true,
      });
      api.fee().then((res) => {
        this.fee = res;
        Toast.clear();
        this.initOk = true;
      });
    },
  },
};
</script>
<style scoped>
.container {
  padding: 10px;
}
.box {
  padding: 10px;
  background-color: #fff;
  margin-bottom: 20px;
}
.box .box-line {
  display: flex;
  justify-content: space-between;
  font-size: 14px;
  line-height: 30px;
}
.box .box-line:last-child {
  border-top: 1px solid #eee;
}
.box .box-line span:first-child {
  font-size: 12px;
  color: #555;
}
.box .box-line:last-child .red {
  color: red;
}
</style>
